











/* tslint:disable:no-console */
import { Component, Vue } from "vue-property-decorator";

import mixins from "@/mixin/index.ts";
import routerMixin from "@/mixin/router-mixin.ts";
import NavBar from "@/components/Nav-bar.vue";
import BlockBox from "@/components/common/Block-box.vue";
import NewsList from "@/components/block/News-list.vue";
import updateTdk from "@/utils/tdk";

import { getHomePageData } from "@/api";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "News",
  components: { NavBar, BlockBox, NewsList },
  mixins: [mixins, routerMixin]
})
export default class News extends Vue {
  // 初始数据可以直接声明为实例的属性
  private tempBlock: any = {};
  private block: any = {};
  private navs: any[] = [];

  private tdk(self: any) {
    return {
      title: `-${self.$route.query.source_block_name}`,
    };
  }
  private serverPrefetch(): any {
    updateTdk(this);
  }
  private mounted(): void {
    const self: any = this;
    self.navs.push({
      name: self.utils.getUrlParam("source_block_name")
    });
    self.getData()
  }
  private getData(more?: string) {
    const self: any = this;
    updateTdk(this);
    const rankId: number =
      Number(self.utils.getUrlParam("source_rank_id")) || 0;
    const blockType: string = self.utils.getUrlParam("source_block_type") || "";
    const blockId: number =
      Number(self.utils.getUrlParam("source_block_id")) || 0;
    if (!rankId) {
      this.$router.push({
        name: "index"
      });
      return;
    }
    if (!more) { self.$doMotion(self, "LOADING_SWITCH", true)};
    // 获取首页数据
    return this.$store
      .dispatch("fetchNewsList", {
        context: this,
        rankId,
        params: { blockType, blockId, start: self.currIndex, max: self.pageMax }
      })
      .then((result: any) => {
        const block = result.value;
        if (block) {
          block.data_source = "newsList";
          block.block_id = blockId;
          block.block_name = self.utils.getUrlParam("source_block_name");
          block.block_type = blockType;
          block.rank_id = rankId;
          block.blockLink = "newsDetail";
          // 更多
          if (self.block.data) {
            block.data = self.block.data.concat(block.data)
          }
          self.block = block
          self.dataMore = block.more
          self.$doMotion(self, "LOADING_SWITCH", false);
          if (!more) {
            self.loadStatus1 = "ready" + self.currIndex;
          } else {
            self.loadStatus1 = "loaded" + self.currIndex;
          }
        }
      }).catch((err) => {
        self.$toast.show(err)
        self.loadStatus1 = 'end' + self.currIndex
      });
  };
}
